import React from "react";
import LegalTextBlock from "../components/LegalTextBlock";
import LegalHeading3 from "../components/LegalHeading3";
import LegalHeading2 from "../components/LegalHeading2";
import LegalList from "../components/LegalList";

function privacy(props) {
  return (
    <div className="py-10 max-w-5xl mx-auto text-left">
      <LegalHeading2>Privacy policy</LegalHeading2>
      <LegalHeading3>1. Who are we?</LegalHeading3>
      <LegalTextBlock>
        In the context of the application of the GDPR, SRL Dahu Partners,
        hereinafter referred to as <strong>"OOOFER"</strong>, is the data
        controller.
      </LegalTextBlock>
      <LegalTextBlock>
        Here is our full contact information:
        <br />
        Dahu Partners SRL:
        <br />
        15 Rue Cervantès:
        <br />
        1190 Brussels:
        <br />
        E-mail: info[at]ooofer.com.
        <br />
      </LegalTextBlock>
      <LegalTextBlock>
        If you have any questions about the processing of your personal data or
        wish to exercise your rights under the GDPR, please contact our Data
        Protection Officer (DPO) by e-mail at: info[at]ooofer.com.
      </LegalTextBlock>
      <LegalHeading3>2. To whom is this Privacy Policy directed?</LegalHeading3>
      <LegalTextBlock>
        This Privacy Policy is directed to the following categories of data
        subjects:
        <LegalList>
          <li>our prospects; </li>
          <li>our subscribers; </li>
          <li>our business partners; </li>
          <li> Visitors to our website; </li>
          <li>Persons who contact us; </li>
        </LegalList>
      </LegalTextBlock>
      <LegalHeading3>3. Our commitment</LegalHeading3>
      <LegalTextBlock>
        OOOFER takes the protection of your personal data very seriously. This
        Privacy Policy explains how and why we collect and use your data.
      </LegalTextBlock>
      <LegalTextBlock>
        We process your personal data in accordance with the applicable
        legislation on the protection of personal data, which includes the
        General Data Protection Regulation (Regulation 2016/679) ("
        <strong>GDPR</strong>") and Directive 2002/58/EC ("
        <strong>ePrivacy Directive</strong>"), the Belgian data protection laws
        including the law of July 30, 2018 on the protection of natural persons
        with regard to the processing of personal data, or any other legislation
        applicable to the processing of your personal data, depending on where
        you live.
      </LegalTextBlock>
      <LegalHeading3>
        4. For what purposes do we process your personal data?
      </LegalHeading3>
      <LegalTextBlock>
        This Privacy Policy applies only to "personal data" within the meaning
        of the GDPR, excluding statistical results provided as aggregated and
        anonymized data by OOOFER.
      </LegalTextBlock>
      <LegalTextBlock>
        “Personal data" is defined as data relating to an identified or
        identifiable individual, whether directly or indirectly, that (i) you
        provide to us yourself, or (ii) we attribute to you to enable you to use
        our services, or (iii) is generated by your use of our services or by
        your activity on our website.
      </LegalTextBlock>
      <LegalHeading3>
        5. If you are a prospect or if you contact us
      </LegalHeading3>
      <LegalTextBlock>
        If you are a prospect to become a subscriber, we process the following
        data:
        <LegalList>
          <li>
            your personal identification data (last name, first name, date of
            birth);
          </li>
          <li>your contact details (email, telephone, postal address);</li>
          <li>your professional data (professional situation, status);</li>
          <li>your geolocation data;</li>
          <li>your preferences.</li>
        </LegalList>
      </LegalTextBlock>
      <LegalTextBlock>
        If you are a prospect to become a business partner, we process the
        following data:
        <LegalList>
          <li>your identification data (last name, first name);</li>
          <li>your contact details (email, telephone);</li>
          <li>your professional data (company).</li>
        </LegalList>
      </LegalTextBlock>
      <LegalTextBlock>
        We process this data on the basis of your consent. This data allows us
        to contact you when you have left your contact information to obtain a
        quote or additional information on OOOFER services.
      </LegalTextBlock>
      <LegalTextBlock>
        It also allows us to send you targeted advertisements or information
        about OOOFER activities and/or our products (newsletters). OOOFER has a
        legitimate interest in promoting its services to people who have come
        into contact with it, who have shown an interest in its activities or to
        its prospects. The sending of advertisements or newsletters by email or
        by text message is carried out by OOOFER only with the prior consent of
        the person concerned (opt-in).
      </LegalTextBlock>
      <LegalHeading3>6. If you are a subscriber/customer</LegalHeading3>
      <LegalTextBlock>
        We process:
        <LegalList>
          <li>
            your personal identification data (name, first name, date of birth);{" "}
          </li>
          <li>your contact details (email, telephone);</li>
          <li>your professional data (professional situation, status);</li>
          <li>
            your billing data (postal address, country, telephone, payment
            method);
          </li>
          <li>your geolocation data;</li>
          <li>your preferences;</li>
          <li>your IP address and your log in data on the OOOFER Platform.</li>
        </LegalList>
      </LegalTextBlock>
      <LegalTextBlock>
        We use the above data in order to:
        <LegalList>
          <li>
            conclude and execute the contract you have signed or wish to sign in
            order to benefit from our services;
          </li>
          <li>
            send you targeted advertisements to promote our services (soft
            opt-in);{" "}
          </li>
          <li>
            analyze the profile of our customers and adapt our services to the
            needs of our customers (legitimate interest).
          </li>
        </LegalList>
      </LegalTextBlock>
      <LegalHeading3>7. If you are a business partner</LegalHeading3>
      We process:
      <LegalTextBlock>
        <LegalList>
          <li>your personal identification data (name, first name); </li>
          <li>your contact details (email, telephone);</li>
          <li>your professional data (company, function);</li>
          <li>your IP address and your log in data on the OOOFER Platform.</li>
        </LegalList>
      </LegalTextBlock>
      <LegalTextBlock>
        We use the above data in order to:
        <LegalList>
          <li>
            conclude and execute the contract that you have signed or wish to
            sign in order to benefit from our services;
          </li>
          <li>
            send you targeted advertisements to promote our services (soft
            opt-in).
          </li>
        </LegalList>
      </LegalTextBlock>
      <LegalHeading3>8. If you are a visitor to our website </LegalHeading3>
      <LegalTextBlock>
        When you visit our website, we collect certain information about your
        browser, IP address, and so on, in order to, among other things, make
        your use of our website as efficient and comfortable as possible and to
        protect our IT systems from attacks and other illegal activities. This
        data is collected by means of cookies (see Cookie Policy).
      </LegalTextBlock>
      <LegalHeading3>
        9. How long will your personal data be retained?
      </LegalHeading3>
      <LegalTextBlock>
        Your data is retained by us for the time necessary to conclude the
        contract, to execute the contract or to follow up our contractual
        relationship. We delete your personal data as soon as the purpose for
        which it was collected and processed ceases to exist and within the
        following periods:
      </LegalTextBlock>
      <LegalTextBlock>
        <LegalList>
          <li>
            Data processed within the framework of the execution of a (future)
            contract will be actively retained for as long as is necessary for
            the execution of the contract or for the follow-up of our
            contractual relationship and will then be archived for the legal
            period of prescription of the contractual actions of 10 years,
            before their definitive deletion at the end of this prescription;
          </li>

          <li>
            The Data of prospects processed within the framework of
            pre-contractual measures or of persons who have contacted us without
            concluding a contract are kept and will be deleted after 2 years if
            no commercial action has been undertaken by the prospect or the
            person who made contact within this period;
          </li>

          <li>
            The information collected during your visit to our website will be
            deleted in accordance with the Cookie policy.
          </li>
        </LegalList>
      </LegalTextBlock>
      <LegalHeading3>
        10. What are the security measures put in place by OOOFER?
      </LegalHeading3>
      <LegalTextBlock>
        In order to protect your personal data against loss, destruction and
        unauthorized access or manipulation, OOOFER has taken both technical and
        organizational security measures.
      </LegalTextBlock>
      <LegalTextBlock>
        This means, on the one hand, that our computer system is equipped with
        backup, filtering, firewalls and data encryption systems, in accordance
        with the relevant industry security standards and, on the other hand,
        that our employees have been made aware of the risks related to data
        breaches and trained to handle your personal data properly. Furthermore,
        access to your personal data is only granted to authorized persons in
        the performance of their duties.
      </LegalTextBlock>
      <LegalHeading3>
        11. To whom may your personal data be transferred?
      </LegalHeading3>
      <LegalTextBlock>
        Your personal data may be transferred to our suppliers, subcontractors
        and professional service providers, who provide services that include
        data processed on our behalf. These parties may only process your
        personal data in order to perform their duties and within the limits of
        our instructions.
      </LegalTextBlock>
      <LegalTextBlock>
        When our suppliers, subcontractors and service providers are located in
        countries outside the European Economic Area, OOOFER guarantees that
        appropriate precautions and security measures are put in place to ensure
        the security and confidentiality of your personal data. OOOFER will take
        particular care to take the following guarantees:
      </LegalTextBlock>
      <LegalTextBlock>
        <LegalList>
          <li>
            the country to which the personal data is transferred has received
            an adequacy decision from the European Commission under Article 45
            of the GDPR and the transfer falls within the scope of that adequacy
            decision;{" "}
          </li>
          <li>
            the country to which the personal data is transferred has concluded
            a contract with the recipient of the personal data containing the
            standard contractual clauses for the protection of personal data
            adopted by the European Commission under Article 47 of the GDPR.
          </li>
        </LegalList>
      </LegalTextBlock>
      <LegalTextBlock>
        We may transfer your personal data to public authorities or other third
        parties when required by law.
      </LegalTextBlock>
      <LegalTextBlock>
        Apart from these cases, OOOFER does not communicate any personal data,
        pseudonymized or not, to third parties.
      </LegalTextBlock>
      <LegalHeading3>
        12. What are your rights regarding the processing of your personal data?
      </LegalHeading3>
      <LegalTextBlock>
        The GDPR gives you the following rights, which you can exercise by
        emailing our DPO at: info[at]ooofer.com.
      </LegalTextBlock>
      <LegalTextBlock>
        <strong>Right of access:</strong> where permitted by law, you have the
        right to ask whether we process your personal data and precisely what
        personal data we process about you. The member area of the OOOFER
        Platform allows you to have access to the vast majority of personal data
        that are processed by our services.
      </LegalTextBlock>
      <LegalTextBlock>
        In any case, a possible request to access will only be granted if OOOFER
        manages, on the basis of the information in its possession, to identify
        you with certainty. Any request for access to your data must be
        accompanied by a copy of your identity card.
      </LegalTextBlock>
      <LegalTextBlock>
        OOOFER has 30 days to respond to your request, this period only starting
        from the moment OOOFER is in possession of all the information required
        to satisfy your request.
      </LegalTextBlock>
      <LegalTextBlock>
        <strong>Right of rectification:</strong> you may request that we rectify
        and/or complete your inaccurate or incomplete personal data.
      </LegalTextBlock>
      <LegalTextBlock>
        <strong>Right to erasure:</strong> without prejudice to the legal
        retention periods set out in Article 5, you may ask us to erase your
        personal data, in particular when it is no longer necessary for the
        purposes for which it was collected or processed, or if you have revoked
        your consent to its processing and there is no other legal basis for its
        processing.
      </LegalTextBlock>
      <LegalTextBlock>
        <strong>Right to restriction of processing:</strong> under certain
        conditions, you have the right to request restricted processing, in
        which case your personal data may, with the exception of its
        conservation, be processed by OOOFER only with your consent or for the
        establishment, exercise or defense of legal claims, for the protection
        of the rights of third parties or for important reasons of public
        interest.import LegalHeading2 from '../components/LegalHeading2';
      </LegalTextBlock>
      <LegalTextBlock>
        <strong>Right to data portability:</strong> if technically possible, you
        have the right to ask us to transfer your personal data to another data
        controller.
      </LegalTextBlock>
      <LegalTextBlock>
        <strong>Right to object:</strong> you always have the right to object,
        without giving any reason and free of charge, to the use of your
        personal data for direct marketing purposes. In particular, you can
        unsubscribe at any time from newsletters sent to you by activating the
        link provided for this purpose in each e-mail or advertisement or by
        sending us a request to this effect to the address: info[at]ooofer.com.{" "}
      </LegalTextBlock>
      <LegalTextBlock>
        <strong>Right to withdraw your consent: </strong>if the processing of
        your personal data is based solely on your consent, you have the right
        to revoke it at any time. In this context, it should be noted that the
        withdrawal of your consent will not affect the lawfulness of processing
        carried out prior to such withdrawal, or processing deriving from any
        other basis of legitimacy than consent.
      </LegalTextBlock>
      <LegalTextBlock>
        <strong>
          Right to lodge a complaint with the supervisory authority:
        </strong>{" "}
        If you believe that your rights in relation to the processing of your
        personal date to which you are subject are not respected by OOOFER, you
        may lodge a complaint at : info[at]ooofer.com or, if not satisfactorily
        dealt with, to the competent Data Protection Authority.
      </LegalTextBlock>
      <LegalHeading3>13. Linked websites</LegalHeading3>
      <LegalTextBlock>
        OOOFER is not responsible for the privacy policies and practices of
        other websites, even if you have accessed a third-party website, such as
        our business partners, through links that may be present on our website.
        We recommend that you check the policy of each website you visit and
        contact the owner or operator of that website if you have any concerns
        or questions
      </LegalTextBlock>
      <LegalHeading3>14. Changes to this Policy</LegalHeading3>
      <LegalTextBlock>
        OOOFER reserves the right to amend or modify this Policy without notice.
        If necessary, OOOFER will post the changes on this page. It is your
        responsibility to check the Policy each time you visit this website.
      </LegalTextBlock>
    </div>
  );
}

export default privacy;
